@import "../../sass/variables";
@import "../../sass/mixins/rem";
@import "../../sass/mixins/mq";

.clients {
    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; }

    .title {
        width: calc(100% - 120px); }

    .swiper-button-prev,
    .swiper-button-next {
        align-self: flex-end;
        display: block;
        margin: 0 0 rem(76px) 39px;
        width: 21px;
        height: 34px;
        cursor: pointer;
        transition: opacity $animation;

        &.swiper-button-disabled {
            opacity: .4;
            cursor: default;
            pointer-events: none; }

        &:hover {
            svg {
                stroke: $colorRed;
                fill: $colorRed; } }

        svg {
            display: block;
            width: 21px;
            height: 34px;
            stroke: $colorText;
            fill: $colorText;
            transition: stroke $animation, fill $animation; } }

    .swiper-button-prev {
        svg {
            transform: rotate(180deg); } }
    &__item {
        &s {
            width: 100%;
            &:not(.swiper-container-initialized) {
                .swiper-wrapper {
                    display: flex;
                    flex-wrap: wrap; } } }

        width: 33.33%;
        height: auto !important;
        color: $colorWhite;
        background: $colorWhite;
        position: relative;
        transition: color $animation, box-shadow $animation;

        @include mq($until: desktop) {
            width: 100%; }

        &:hover {
            color: $colorText;
            box-shadow: 10px 7px 44px rgba(0, 0, 0, 0.08);

            .clients__item-title {
                color: $colorText !important;
                text-shadow: none !important; } }

        &-image {
            width: 100%;
            height: 100%;
            background: $colorDark no-repeat 50% 50% / cover;
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
            transition: opacity $animation;

            .clients__item:hover & {
                opacity: 0; } }

        &-wrap {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            //padding: rem(58px)
            @include fluid-property('padding', $mobileWidth, $fluidWidthMin, $fluidWidthMax, 15px, 15px, 58px);
            height: 100%;
            position: relative; }

        &-title {
            font-weight: 800;
            font-size: rem(30px);
            line-height: (38/30); }

        &-desc {
            order: -1;
            margin: rem(30px) 0 0;
            font-size: rem(22px);
            line-height: (35/22);
            visibility: hidden;

            .clients__item:hover & {
                order: initial;
                visibility: visible; } }

        &-more {
            margin: rem(70px) 0 0;
            text-decoration: none;
            font-weight: 600;
            color: $colorWhite;
            transition: color $animation;

            .clients__item:hover & {
                color: $colorText; }

            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0; }

            svg {
                display: inline-block;
                vertical-align: middle;
                margin-left: rem(12px);
                width: 35px;
                height: 10px;
                fill: $colorWhite;
                transition: fill $animation;

                .clients__item:hover & {
                    fill: $colorText; } } } } }
