@import "../../sass/variables";
@import "../../sass/mixins/rem";
@import "../../sass/mixins/mq";

.footer {
    &__column {
        &s {
            @include mq($from: tablet) {
                margin: rem(-130px) 0 0;
                display: flex;
                justify-content: space-between; } }

        margin: rem(130px) 0 0; }

    &__form {
        position: relative;
        @include mq($from: tablet) {
            width: calc(100% - #{rem(400px)});
            max-width: rem(810px); }

        &.msg-success {
            > * {
                visibility: hidden; }
            .footer__form-msg--success {
                display: flex !important;
                visibility: visible !important; } }
        &.msg-error {
            > * {
                visibility: hidden; }
            .footer__form-msg--error {
                display: flex !important;
                visibility: visible !important; } }

        &-msg {
            justify-content: center;
            align-items: center;
            border: 1px solid;
            padding: rem(32px);
            text-align: center;
            font-size: rem(20px);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &--success {
                border-color: #e9f5ea;
                background: #e9f5ea;
                color: #4caf50; }

            &--error {
                border-color: #ff5252;
                background: $colorWhite;
                color: #ff5252; }

            p {
                display: block;
                font-size: rem(32px); } }

        &-title {
            margin: 0 0 rem(32px);
            @include fluid-property('font-size', $mobileWidth, $fluidWidthMin, $fluidWidthMax, 30px, 30px, 58px);
            line-height: (74/58); }

        p {
            margin: 0 0 rem(32px);
            font-size: rem(22);
            line-height: (35/22); }

        &-foot {
            display: flex;
            align-items: center;

            @include mq($until: mobileLandscape) {
                display: block;
                text-align: center; }

            button {
                display: block;
                margin: 0 rem(50px) 0 0;
                border: 2px solid $colorText;
                border-radius: 0;
                padding: rem(20px) rem(35px);
                text-align: center;
                font: 600 rem(18)/#{(22/18)} $fontFamily;
                color: $colorText;
                background: transparent;
                outline: none;
                cursor: pointer;
                transition: border $animation, color $animation;

                @include mq($until: mobileLandscape) {
                    margin: 0 0 rem(20px);
                    width: 100%; }

                &:hover {
                    border-color: $colorRed;
                    color: $colorRed;

                    svg {
                        fill: $colorRed; } }

                svg {
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: rem(12px);
                    width: 35px;
                    height: 10px;
                    fill: $colorText;
                    transition: fill $animation; } } } }

    &__contacts {
        text-align: center;

        @include mq($from: tablet) {
            text-align: left;
            min-width: rem(280px); }

        &--alt {
            @include mq($from: tablet) {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                div {
                    width: calc(50% - 20px); } } }

        .title {
            width: 100%; }
        //&-title
        //  display: inline-block
        //  padding: rem(11px) rem(18px)
        //  width: 100%
        //  font-size: rem(18px)
        //  color: $colorWhite
        //  background: $colorDark

        div {
            margin-top: rem(80px); }

        p {
            margin: rem(32px) 0 0;
            font-weight: 800;
            font-size: rem(30px);
            line-height: (38/30); }

        a {
            color: $colorText;
            transition: color $animation;

            &:hover {
                color: $colorRed; }

            &[href^="tel:"] {
                text-decoration: none; }

            &[href^="mailto:"] {
                text-decoration: underline; } } }

    &__copyrights {
        margin: rem(130px) 0 rem(75px);
        font-size: rem(16px);

        @include mq($until: tablet) {
            text-align: center; } } }
