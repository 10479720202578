@import "../../sass/variables";
@import "../../sass/mixins/rem";
@import "../../sass/mixins/mq";

.info {
    &__title {
        .info--everest & {
            max-width: rem(1200); }

        span {
            color: $colorBlue;
            //.info--everest &
            //  color: #3763ff
            .info--extyl & {
                color: $colorRed; } } }

    &__block {
        &s {
            display: flex;
            flex-wrap: wrap; }

        border: 1px solid #d1cfd1;
        //padding: rem(58px)
        @include fluid-property('padding', $mobileWidth, $fluidWidthMin, $fluidWidthMax, 15px, 15px, 58px);
        width: 33.33%;

        @include mq($from: desktop) {
            border-left-width: 0;
            &:nth-child(3n+1) {
                border-left-width: 1px; } }
        @include mq($until: desktop) {
            & + & {
                border-top-width: 0; } }

        @include mq($until: desktop) {
            width: 100%; }

        h4 {
            //margin: 0 0 rem(40px)
            margin: 0;
            @include fluid-property('margin-bottom', $mobileWidth, $fluidWidthMin, $fluidWidthMax, 15px, 15px, 40px);
            font-size: rem(30px);
            line-height: (38/30); }

        ul {
            list-style: none;
            margin: 0;
            padding: 0; }

        li {
            padding-left: 18px;
            position: relative;

            + li {
                margin-top: rem(27px); }

            &::before {
                content: '';
                width: 4px;
                height: 4px;
                background: $colorText;
                position: absolute;
                top: rem(12px);
                left: 0; } } } }
