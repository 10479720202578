@import "../../sass/variables";
@import "../../sass/mixins/rem";

.header {
    &__logo {
        margin: rem(46px) 0;
        max-width: 100%;
        position: absolute;
        top: 0;
        z-index: 2;

        svg {
            display: block;
            max-width: 100%;
            min-width: 280px; } } }
