@import '../../sass/variables';
@import '../../sass/mixins/rem';
@import '../../sass/mixins/mq';

.fields {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 0 rem(-50px); }


.field__container {
    margin: 0 0 rem(30px) rem(50px);
    width: calc(100% - #{rem(50)});

    &--half {
        @include mq($from: desktop) {
            width: calc(50% - #{rem(50)}); } }

    &.error {
        .field {
            border-color: $colorRed; }

        .textarea__wrap::after {
            border-color: transparent $colorRed; } }

    &-msg {
        margin: 4px 0 0;
        font-size: 12px;
        color: $colorRed; } }

input.field,
textarea.field {
    display: block;
    margin: 0;
    border: 0;
    border-bottom: 1px solid $colorText;
    border-radius: 0;
    width: 100%;
    padding: rem(26px) rem(16px);
    font: normal rem(16px)/#{(19/16)} $fontFamily;
    color: $colorText;
    background: transparent;
    outline: none;

    @include mq($until: desktop) {
        padding: 26px 16px;
        font-size: 16px; }

    &::placeholder {
        color: $colorText;
        opacity: .6; } }

textarea.field {
    min-height: rem(72px);
    height: rem(72px);
    resize: vertical;

    @include mq($until: desktop) {
        min-height: 72px; } }

.textarea__wrap {
    position: relative;

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 1px;
        pointer-events: none; }

    &::before {
        width: 20px;
        height: 20px;
        background: $colorWhite; }

    &::after {
        content: '';
        display: block;
        border-width: 8px 8px 0 0;
        border-style: solid;
        border-color: transparent $colorText;
        width: 0;
        height: 0; } }

label.field {
    display: block;
    margin: 0 0 rem(30px);
    border: 0;
    padding: rem(26px) rem(16px) rem(26px) 28px;
    font-size: rem(16px);
    line-height: (19/16);
    color: $colorText;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: color $animation;

    @include mq($until: desktop) {
        font-size: 16px;
        padding: 26px 16px 26px 28px; }

    &:hover {
        color: $colorRed; }

    input[type="file"] {
        display: block;
        margin: 0 !important;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        pointer-events: none; }

    svg {
        display: block;
        margin-top: -11px;
        width: 15px;
        height: 22px;
        position: absolute;
        top: 50%;
        left: 0;
        fill: $colorText;
        pointer-events: none; }

    span {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; } }
