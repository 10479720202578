@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Regular.eot');
    src: url('../fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/Gilroy-Regular.woff') format('woff'), url('../fonts/Gilroy-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal; }

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-ExtraBold.eot');
    src: url('../fonts/Gilroy-ExtraBold.eot?#iefix') format('embedded-opentype'), url('../fonts/Gilroy-ExtraBold.woff') format('woff'), url('../fonts/Gilroy-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal; }
