@import "../variables";

@function rem($size) {
    @return strip-unit($size) / strip-unit($fontSize) * 1rem
}

@function strip-unit($n) {
    @return $n / ($n * 0 + 1)
}

@mixin fluid-property($property, $mobile-vw, $min-vw, $max-vw, $mobile-value, $min-value, $max-value) {
    $u1: unit($mobile-vw);
    $u2: unit($min-vw);
    $u3: unit($max-vw);
    $u4: unit($mobile-value);
    $u5: unit($min-value);
    $u6: unit($max-value);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 and $u1 == $u5 and $u1 == $u6 {
        & {
            #{$property}: $min-value;
            @media screen and (min-width: $min-vw) {
                #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }
            @media screen and (min-width: $max-vw) {
                #{$property}: $max-value;
            }
            @media (max-width: $mobile-vw) {
                #{$property}: $mobile-value;
            }
        }
    } @else {
        @warn "All params in `fluid-param` must have one unit";
        @warn $u1;
        @warn $u2;
        @warn $u3;
        @warn $u4;
        @warn $u5;
        @warn $u6;
    }
}

@mixin fluid-type($mobile-vw, $min-vw, $max-vw, $mobile-font-size, $min-font-size, $max-font-size) {
    @include fluid-property('font-size', $mobile-vw, $min-vw, $max-vw, $mobile-font-size, $min-font-size, $max-font-size);
}