@charset "UTF-8"; // Fixes an issue where Ruby locale is not set properly
                  // See https://github.com/sass-mq/sass-mq/pull/10

/// Base font size on the `<body>` element
/// @type Number (unit)
$mq-base-font-size: 16px !default;

/// Responsive mode
///
/// Set to `false` to enable support for browsers that do not support @media queries,
/// (IE <= 8, Firefox <= 3, Opera <= 9)
///
/// You could create a stylesheet served exclusively to older browsers,
/// where @media queries are rasterized
///
/// @example
///  // old-ie.scss
///  $mq-responsive: false;
///  @include 'main'; // @media queries in this file will be rasterized up to $mq-static-breakpoint
///                   // larger breakpoints will be ignored
///
/// @type Boolean
/// @link https://github.com/sass-mq/sass-mq#responsive-mode-off Disabled responsive mode documentation
$mq-responsive: true !default;

/// Breakpoint list
///
/// Name your breakpoints in a way that creates a ubiquitous language
/// across team members. It will improve communication between
/// stakeholders, designers, developers, and testers.
///
/// @type Map
/// @link https://github.com/sass-mq/sass-mq#seeing-the-currently-active-breakpoint Full documentation and examples
$mq-breakpoints: (
    mobile:  321px,
    tablet:  769px,
    desktop: 1025px,
    wide:    1281px
) !default;

/// Static breakpoint (for fixed-width layouts)
///
/// Define the breakpoint from $mq-breakpoints that should
/// be used as the target width for the fixed-width layout
/// (i.e. when $mq-responsive is set to 'false') in a old-ie.scss
///
/// @example
///  // tablet-only.scss
///  //
///  // Ignore all styles above tablet breakpoint,
///  // and fix the styles (e.g. layout) at tablet width
///  $mq-responsive: false;
///  $mq-static-breakpoint: tablet;
///  @include 'main'; // @media queries in this file will be rasterized up to tablet
///                   // larger breakpoints will be ignored
///
/// @type String
/// @link https://github.com/sass-mq/sass-mq#adding-custom-breakpoints Full documentation and examples
$mq-static-breakpoint: desktop !default;

/// Show breakpoints in the top right corner
///
/// If you want to display the currently active breakpoint in the top
/// right corner of your site during development, add the breakpoints
/// to this list, ordered by width, e.g. (mobile, tablet, desktop).
///
/// @type map
$mq-show-breakpoints: () !default;

/// Customize the media type (e.g. `@media screen` or `@media print`)
/// By default sass-mq uses an "all" media type (`@media all and …`)
///
/// @type String
/// @link https://github.com/sass-mq/sass-mq#changing-media-type Full documentation and examples
$mq-media-type: all !default;

/// Convert pixels to ems
///
/// @param {Number} $px - value to convert
/// @param {Number} $base-font-size ($mq-base-font-size) - `<body>` font size
///
/// @example
///  $font-size-in-ems: mq-px2em(16px);
///  p { font-size: mq-px2em(16px); }
///
/// @requires $mq-base-font-size
/// @returns {Number}
@function mq-px2em($px/*, $base-font-size: $mq-base-font-size*/) {
   // @if (unitless($px)) {
   //      @warn "Assuming #{$px} to be in pixels, attempting to convert it into pixels";
   //      @return mq-px2em($px + 0px); // That may fail.
   //  } @else if (unit($px) == em) {*/
   //      @return $px;
   //  }


   //@return ($px / $base-font-size) * 1em;
   @return $px;
}

/// Get a breakpoint's width
///
/// @param {String} $name - Name of the breakpoint. One of $mq-breakpoints
///
/// @example
///  $tablet-width: mq-get-breakpoint-width(tablet);
///  @media (min-width: mq-get-breakpoint-width(desktop)) {}
///
/// @requires {Variable} $mq-breakpoints
///
/// @returns {Number} Value in pixels
@function mq-get-breakpoint-width($name, $breakpoints: $mq-breakpoints) {
    @if(map-has-key($breakpoints, $name)) {
        @return map-get($breakpoints, $name);
    } @else {
        @warn "Breakpoint #{$name} wasn't found in $breakpoints.";
    }
}

/// Media Query mixin
///
/// @param {String | Boolean} $from (false) - One of $mq-breakpoints
/// @param {String | Boolean} $until (false) - One of $mq-breakpoints
/// @param {String | Boolean} $and (false) - Additional media query parameters
/// @param {String} $media-type ($mq-media-type) - Media type: screen, print…
///
/// @ignore Undocumented API, for advanced use only:
/// @ignore @param {Map} $breakpoints ($mq-breakpoints)
/// @ignore @param {String} $static-breakpoint ($mq-static-breakpoint)
///
/// @content styling rules, wrapped into a @media query when $responsive is true
///
/// @requires {Variable} $mq-media-type
/// @requires {Variable} $mq-breakpoints
/// @requires {Variable} $mq-static-breakpoint
/// @requires {function} mq-px2em
/// @requires {function} mq-get-breakpoint-width
///
/// @link https://github.com/sass-mq/sass-mq#responsive-mode-on-default Full documentation and examples
///
/// @example
///  .element {
///    @include mq($from: mobile) {
///      color: red;
///    }
///    @include mq($until: tablet) {
///      color: blue;
///    }
///    @include mq(mobile, tablet) {
///      color: green;
///    }
///    @include mq($from: tablet, $and: '(orientation: landscape)') {
///      color: teal;
///    }
///    @include mq(950px) {
///      color: hotpink;
///    }
///    @include mq(tablet, $media-type: screen) {
///      color: hotpink;
///    }
///    // Advanced use:
///    $my-breakpoints: ( L: 900px, XL: 1200px );
///    @include mq(L, $breakpoints: $my-breakpoints, $static-breakpoint: L) {
///      color: hotpink;
///    }
///  }
@mixin mq(
    $from: false,
    $until: false,
    $and: false,
    $media-type: $mq-media-type,
    $breakpoints: $mq-breakpoints,
    $responsive: $mq-responsive,
    $static-breakpoint: $mq-static-breakpoint
) {
    $min-width: 0;
    $max-width: 0;
    $media-query: '';

    // From: this breakpoint (inclusive)
    @if $from {
        @if type-of($from) == number {
            $min-width: mq-px2em($from);
        } @else {
            $min-width: mq-px2em(mq-get-breakpoint-width($from, $breakpoints));
        }
    }

    // Until: that breakpoint (exclusive)
    @if $until {
        @if type-of($until) == number {
            $max-width: mq-px2em($until);
        } @else {
            $max-width: mq-px2em(mq-get-breakpoint-width($until, $breakpoints)) - 1px;
        }
    }

    // Responsive support is disabled, rasterize the output outside @media blocks
    // The browser will rely on the cascade itself.
    @if ($responsive == false) {
        $static-breakpoint-width: mq-get-breakpoint-width($static-breakpoint, $breakpoints);
        $target-width: mq-px2em($static-breakpoint-width);
        // Output only rules that start at or span our target width
        @if ($and == false and ($min-width <= $target-width) and (($until == false) or ($max-width >= $target-width))) {
            @content;
        }
    }

    // Responsive support is enabled, output rules inside @media queries
    @else {
        @if $min-width != 0 { $media-query: '#{$media-query} and (min-width: #{$min-width})'; }
        @if $max-width != 0 { $media-query: '#{$media-query} and (max-width: #{$max-width})'; }
        @if $and            { $media-query: '#{$media-query} and #{$and}'; }

        $media-query: unquote("#{$media-query}");

        @media #{$media-type + $media-query} {
            @content;
        }
    }
}

/// Add a breakpoint
///
/// @param {String} $name - Name of the breakpoint
/// @param {Number} $width - Width of the breakpoint
///
/// @requires {Variable} $mq-breakpoints
///
/// @example
///  @include mq-add-breakpoint(tvscreen, 1920px);
///  @include mq(tvscreen) {}
@mixin mq-add-breakpoint($name, $width) {
    $new-breakpoint: ($name: $width);
    $mq-breakpoints: map-merge($mq-breakpoints, $new-breakpoint) !global;
}

@mixin mq-show-breakpoints($show-breakpoints: $mq-show-breakpoints, $breakpoints: $mq-breakpoints) {
    body:before {
        background-color: #fcf8e3;
        border: 1px solid #fbeed5;
        border-color: #c09853;
        border-bottom:0;
        color: #c09853;
        font: small-caption;
        padding: 3px 6px;
        pointer-events: none;
        position: fixed;
        left: 50%;
        bottom: 0;
        z-index: 1000;
        transform: translateX(-50%);

        // Loop through the breakpoints that should be shown
        @each $show-breakpoint in $show-breakpoints {
            $width: mq-get-breakpoint-width($show-breakpoint, $breakpoints);
            @include mq($show-breakpoint, $breakpoints: $breakpoints) {
                content: "#{$show-breakpoint} ≥ #{$width} (#{mq-px2em($width)})";
            }
        }
    }
}

@if length($mq-show-breakpoints) > 0 {
    @include mq-show-breakpoints;
}