// animation
$animation: .25s;

//fonts
$fontWeight: 400;
$fontSize: 18px;
$lineHeight: (30 / 18);
$fontFamily: Gilroy, sans-serif;
$font: $fontWeight #{$fontSize}/#{$lineHeight} $fontFamily;

//адаптация шрифта
$fontSizeMobile: 12px; //размер шрифта (мобильный)
$mobileWidth: 400px; //начало адаптации на мобильный шрифт
$fontSizeMin: 12px; //минимальный размер шрифта
$fontSizeMax: 18px; //максималльный размер шрифта
$fluidWidthMin: 400px; //минимальный размер экрана для адаптации текста
$fluidWidthMax: 1680px; //максималльный размер экрана для адаптации текста

//colors
$colorText: #292929;
$colorWhite: #fff;
$colorDark: #232323;
$colorRed: #ea212d;
$colorBlue: #3763ff;

// breakpoints
$mq-breakpoints: (mobile: 375px, mobileLandscape: 480px, tablet: 768px, desktop: 996px, widescreen: 1200px, large: 1500px, hd: 1920px);

// Show active breakpoint in viewport
//$mq-show-breakpoints: (mobile, mobileLandscape, tablet, desktop, widescreen, large, hd)
